import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import A4 from "../Images/A4";

const OfficialListItem = ({content}) => (
    <div className="official-list-item card h-full flex flex-col">
        <div className="card-image relative flex-initial text-center bg-gray-100">
            {content.relationships.image ? (
                <GatsbyImage
                    image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                    alt={content.title} />
            ) : (
                <A4 alt={content.title}/>
            )}
        </div>
        <div className="card-content flex-1 py-6 px-2 bg-primary border-b-20 border-secondary text-white">
            <div className="title h3 relative mb-4 pb-4 before-underline before-bg-secondary">{content.title}</div>

            {content.presentation &&(
                <div
                    className="content"
                    dangerouslySetInnerHTML={{__html: content.presentation.value}}
                />
            )}

        </div>
    </div>
)

OfficialListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default OfficialListItem;
